<script>
    import classNames from 'classnames'
    import { TableRow } from 'components/Table'
    export let fill = false
    export let rows = []
    let className
    export { className as class }
</script>

<style>
    .Table {
        overflow: auto;
        max-width: 100%;
    }
    .Table-table {
        border: none;
        border-spacing: 0;
    }
    .Table--fill .Table-table {
        width: 100%;
    }</style>

<div class={classNames('Table', className, { 'Table--fill': fill })}>
    <table class="Table-table">
        <slot>
            {#each rows as row}
                <TableRow {...row} />
            {/each}
        </slot>
    </table>
</div>
