<script>
    import classNames from 'classnames'
    import ConditionalLink from 'utils/ConditionalLink'
    export let content = null
    export let to = null
    let className
    export { className as class }
</script>

<style>
    .TableCell {
        border: none;
    }</style>

<th class={classNames('TableHeader', className)}>
    <ConditionalLink if={to} {to} plain>
        <slot>{content}</slot>
    </ConditionalLink>
</th>
