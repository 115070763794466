<script>
    import classNames from 'classnames'
    import { slide } from 'svelte/transition'
    import { sineOut } from 'svelte/easing'

    import Title from 'components/Title'
    import Icon from 'components/Icon'
    import CanonicalLink from 'components/CanonicalLink'
    import Pill from 'components/Pill'

    export let title
    export let content
    export let center
    export let large
    export let isOpen = false
    export let canonicalLink = null
    export let pillText = null

    function toggleOpen() {
        setTimeout(() => (isOpen = !isOpen), 0)
    }
</script>

<style global>
    :global(.Accordion) {
        border-top: 1px solid;
        border-bottom: 1px solid;
    }
    :global(.Accordion) + :global(.Accordion) {
        border-top: none;
    }
    :global(.Accordion-header) {
        padding: 1em 0;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
    }
    :global(.Accordion--center) :global(.Accordion-header) {
        justify-content: center;
    }
    :global(.Accordion--center) :global(.Accordion-icon) {
        display: none;
    }
    :global(.Accordion-title) {
        flex: 1 1 auto;
        margin: 0;
    }
    :global(.Accordion--center) :global(.Accordion-title) {
        flex-grow: 0;
    }
    :global(.Accordion-icon) {
        flex: 0 0 auto;
    }
    :global(.Accordion-contentWrapper) {
        overflow: hidden;
    }
    :global(.Accordion-content) {
        padding: 0 0 1.5em;
    }
    :global(.Accordion-titleWrapper) {
        display: flex;
        flex-direction: row;
        align-items: center;
    }
    :global(.Accordion-pill) {
        margin-left: 0.5rem;
        font-weight: 600;
    }</style>

<section
    itemscope
    itemprop="mainEntity"
    itemtype={'https://schema.org/Question'}
    class={classNames('Accordion', {
        'Accordion--isOpen': isOpen,
        'Accordion--center': center
    })}>

    <CanonicalLink href={canonicalLink}>
        <span class="Accordion-header" on:click={toggleOpen}>
            <div class="Accordion-titleWrapper">
                <Title
                    itemprop="name"
                    level={3}
                    asLevel={large ? 3 : 4}
                    text={title.text || title}
                    class={classNames('Accordion-title', title.class)} />

                {#if pillText && !isOpen}
                    <Pill class="Accordion-pill" size="small">{pillText}</Pill>
                {/if}
            </div>

            <Icon
                type={isOpen ? 'minus' : 'plus'}
                class="Accordion-icon"
                size="big" />
        </span>
    </CanonicalLink>

    <div class="Accordion-contentWrapper">
        {#if isOpen}
            <div
                transition:slide={{ duration: 350, easing: sineOut }}
                class="Accordion-content"
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer">
                <slot>{content}</slot>
            </div>
        {:else}
            <div
                class="hidden-text"
                itemscope
                itemprop="acceptedAnswer"
                itemtype="https://schema.org/Answer">
                <slot>{content}</slot>
            </div>
        {/if}
    </div>
</section>
