<script>
    import classNames from 'classnames'
    import uuid from 'uuid'

    export let type = 'text'
    export let id = uuid()
    export let name = null
    export let label = null
    export let description = null
    export let placeholder = null
    export let min = null
    export let value = null
    export let max = null
    export let step = null
    export let disabled = null
    export let attributes = {}
    export let fill = null
    export let theme = null
    export let large = null
    export let multiline = null // {rows: int}

    let className = null
    export { className as class }

    let inputWrapper

    const filterUndefined = obj =>
        Object.keys(obj).reduce(
            (attrs, key) =>
                typeof obj[key] !== 'undefined'
                    ? { ...attrs, [key]: obj[key] }
                    : attrs,
            {}
        )
    const attrs = filterUndefined({
        ...(attributes || {}),
        id,
        name,
        type,
        min,
        max,
        step,
        disabled,
        placeholder
    })
</script>

<style global>
    :global(:root) {
        --input-border-radius: 4px;
        --input-border-color: var(--color-light-grey);
        --input-border-color-focus: var(--color-dark-blue);
        --input-border-color-hover: rgba(1, 3, 87, 0.12);
    }
    :global(.Input) {
        display: block;
    }
    :global(.Input-wrapper) {
        position: relative;
        color: var(--color-text);
        width: 20em;
        border-radius: 4px;
        border-radius: var(--input-border-radius);
        border: 1px solid var(--color-light-grey);
        border: 1px solid var(--input-border-color);
        display: inline-block;
    }
    :global(.Input-input) {
        -webkit-appearance: textfield; /* To make padding work on Safari */
        position: relative;
        z-index: 1;
        border: none;
        border-radius: 4px;
        border-radius: var(--input-border-radius);
        font: inherit;
        color: inherit;
        background: var(--color-white);
        width: 100%;
        box-sizing: border-box;
        padding: 19px 22px;
        outline: none;
        margin: 0px;
        caret-color: #4a90e2;
        display: block;
        resize: vertical;
    }
    :global(.Input-input::-moz-placeholder) {
        color: var(--color-text);
        opacity: 0.34;
        -moz-transition: opacity var(--transition);
        transition: opacity var(--transition);
    }
    :global(.Input-input:-ms-input-placeholder) {
        color: var(--color-text);
        opacity: 0.34;
        -ms-transition: opacity var(--transition);
        transition: opacity var(--transition);
    }
    :global(.Input-input::placeholder) {
        color: var(--color-text);
        opacity: 0.34;
        transition: opacity var(--transition);
    }
    :global(.Input-input:focus::-moz-placeholder) {
        opacity: 0.34;
    }
    :global(.Input-input:focus:-ms-input-placeholder) {
        opacity: 0.34;
    }
    :global(.Input-input:focus::placeholder) {
        opacity: 0.34;
    }
    :global(.Input-input:hover:not(:focus)) {
        background: var(--color-hover);
        border-color: rgba(1, 3, 87, 0.12);
        border-color: var(--input-border-color-hover);
    }
    :global(.Input-wrapper[focus-within]) {
        border-color: var(--color-dark-blue);
        border-color: var(--input-border-color-focus);
    }
    :global(.Input-wrapper:focus-within) {
        border-color: var(--color-dark-blue);
        border-color: var(--input-border-color-focus);
    }
    :global(.Input--fill) :global(.Input-wrapper) {
        width: 100%;
    }
    :global(.Input--large) :global(.Input-input) {
        line-height: 2em;
    }
    :global(.Input-label) {
        font-weight: var(--font-weight-bold);
        font-size: 21px;
        margin-bottom: 7px;
    }
    :global(.Input-description) {
        font-weight: var(--font-weight-light);
        font-size: 18px;
        margin-bottom: 7px;
    }</style>

<div
    class={classNames('Input', className, {
        'Input--fill': fill,
        [`Input--withTheme theme-${theme}`]: theme,
        'Input--large': large
    })}>
    {#if label}
        <label class="Input-label" for={id}>{label}</label>
    {/if}

    {#if description}
        <div class="Input-description">{description}</div>
    {/if}

    <div class="Input-wrapper" bind:this={inputWrapper}>
        {#if multiline}
            <textarea
                {...attrs}
                class="Input-input"
                bind:value
                autocomplete="off"
                on:input
                on:change
                on:focus
                on:blur
                on:keydown
                rows={multiline.rows || 3} />
        {:else}
            <input
                {...attrs}
                class="Input-input"
                bind:value
                autocomplete="off"
                on:input
                on:change
                on:focus
                on:blur
                on:keydown />
        {/if}

        <slot />
    </div>
</div>
