<script>
    import Link from 'components/Link'

    export let href = null
</script>

<style global>
    :global(.CanonicalLink) {
        width: 100%;
    }</style>

{#if href}
    <Link class="CanonicalLink" plain disabled={true} to={href}>
        <slot />
    </Link>
{:else}
    <slot />
{/if}
