<script>
    import classNames from 'classnames'
    import { TableCell, TableHeader } from 'components/Table'

    export let cells = []
    let className
    export { className as class }
</script>

<style>
    .TableRow {
        border: none;
    }</style>

<tr class={classNames('TableRow', className)}>
    <slot>
        {#each cells as cell (cell.id)}
            {#if cell.heading}
                <TableHeader {...cell} />
            {:else}
                <TableCell {...cell} />
            {/if}
        {/each}
    </slot>
</tr>
