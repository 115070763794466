<script>
    import classNames from 'classnames'
    import ConditionalLink from 'utils/ConditionalLink'
    export let content = null
    export let to = null
    export let prefetch = false
    export let width = null
    export let align = null
    let className
    export { className as class }
</script>

<style>
    .TableCell {
        padding: 0.25em;
        position: relative;
    }
    .TableCell:first-child {
        padding-left: 0;
    }
    .TableCell:last-child {
        padding-right: 0;
    }</style>

<td class={classNames('TableCell', className)} {width} {align}>
    <ConditionalLink
        if={to}
        linkProps={{ to, plain: true, cover: true, rel: prefetch ? 'prefetch' : undefined }}
        class="TableCell-link">
        <slot>{content}</slot>
    </ConditionalLink>
</td>
